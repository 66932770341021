import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import {
  NotificationService,
  LocalStorageService,
  StorageKeyService,
} from '@seamless/core';
import { DealerSettingsModel } from '@models/dealer/dealer-settings.model';
import { environment } from '@environments/environment';
import { StorageKey as LivemarketStorageKeys } from '@models/LiveMarketStorageKeys';
import { DealerRegoSearchSubscriptionInformation } from '@models/dealer/dealer-rego-search-subscription-information.model';
import { DealerLivemarketSubscriptionInformation } from '@models/dealer/dealer-livemarket-subscription-information.model';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  private dealerSettingsSubject: BehaviorSubject<DealerSettingsModel>;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private storageKeyService: StorageKeyService
  ) {
    this.localStorageService.delete(LivemarketStorageKeys.DEALER_SETTINGS);
    this.dealerSettingsSubject = new BehaviorSubject<DealerSettingsModel>(null);
  }

  get dealerSettings(): Observable<DealerSettingsModel> {
    return this.dealerSettingsSubject.pipe(filter((val) => !!val));
  }
  updateDealerSettings(settings: DealerSettingsModel) {
    this.localStorageService.set(
      LivemarketStorageKeys.DEALER_SETTINGS,
      settings
    );
    this.dealerSettingsSubject.next(settings);
  }

  getDealerSettings(): Observable<DealerSettingsModel> {
    const apiPath =
      environment.seamless.api.experience +
      'api/' +
      `${environment.seamless.tenant}/` +
      this.localStorageService.get(this.storageKeyService.SELLER_GUID) +
      '/dealersettings';

    return this.http
      .get(apiPath)
      .pipe(catchError((error) => this.handleError(error)));
  }

  putDealerSettings(
    model: DealerSettingsModel
  ): Observable<DealerSettingsModel> {
    const apiPath =
      environment.seamless.api.experience +
      'api/' +
      `${environment.seamless.tenant}/` +
      this.localStorageService.get(this.storageKeyService.SELLER_GUID) +
      '/dealersettings';

    return this.http
      .put<DealerSettingsModel>(apiPath, model)
      .pipe(catchError((error) => this.handleError(error)));
  }

  refreshDealerSettings() {
    this.getDealerSettings().subscribe(
      (resp) => {
        this.updateDealerSettings(resp);
      },
      (error) => this.handleError(error)
    );
  }
  getLmSubscriptionInformation(): Observable<DealerRegoSearchSubscriptionInformation> {
    const apiPath =
      environment.seamless.api.experience +
      'api/' +
      `${environment.seamlessPublic.ownerId}/` +
      this.localStorageService.get(this.storageKeyService.SELLER_GUID) +
      '/rego-search-subscription';

    return this.http.get<DealerRegoSearchSubscriptionInformation>(apiPath);
  }

  getLivemarketSubscriptionDates(): Observable<DealerLivemarketSubscriptionInformation> {
    const apiPath =
      environment.seamless.api.experience +
      'api/' +
      'lm-subscription-data';

    return this.http.get<DealerLivemarketSubscriptionInformation>(apiPath);
  }

  private handleError(error: any): Observable<any> {
    // console.log('Dealer Service', error.message); // for demo purposes only
    // this.notificationService.display({
    //     title: 'Error',
    //     message: error.message,
    //     type: 'fail',
    //     isRead: false
    // });
    return throwError(error);
  }
}
