import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lm-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  @Input() advertimentImg: string;
  @Input() advertimentImgAltText: string;
  @Input() href: string;

  constructor() { }

  ngOnInit() {
  }

}
