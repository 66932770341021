import { Component, OnInit, OnDestroy } from '@angular/core';
import { DealerService } from '@shared/services/dealer/dealer.service';
import { Subscription } from 'rxjs';
import { AuthenticationService, SellerService } from '@seamless/core';
import { DealerDropdownService, Dealer } from '@seamless/livemarket';
import { environment } from '@environments/environment';
import { filter, switchMap, take } from 'rxjs/operators';
import { SellerSettingsDTO } from '@seamless/core/lib/dto/seller/seller-settings.dto';
import { SettingsService } from "@shared/services/settings/settings.service";

@Component({
    selector: 'lm-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private subscribers: any = {};

    appraisalSolutionMoreInfoUrl: string = environment.appraisalSolutionsMoreInfo.url;
    sourcingValuationsUrl: string = environment.sourcingLivemarketModeRedirect.valuationsListUrl;
    livemarketInventoryListingsUrl: string = environment.livemarketInventoryListUrl;
    showNewLivemarketInventory: boolean = null;

    constructor(
        private dealerService: DealerService,
        private dealerDropdownService: DealerDropdownService,
        private settingsService: SettingsService
    ) {}

    ngOnInit() {
        this.subscribers.activeDealer = this.dealerDropdownService.activeDealer.subscribe((dealer: Dealer) => {
            this.dealerService.refreshDealerSettings();
            this.settingsService.getSellerSettings().subscribe(showNewLivemarketInventory => {
                this.showNewLivemarketInventory = showNewLivemarketInventory?.pilotPrograms.includes("LIVEMARKET_INVENTORY") ?? false;
            });
        });
    
    }

    ngOnDestroy() {
        for (const key in this.subscribers) {
            if (this.subscribers.hasOwnProperty(key)) {
                const subscriber = this.subscribers[key];

                if (subscriber instanceof Subscription) {
                    subscriber.unsubscribe();
                }
            }
        }
    }
}
